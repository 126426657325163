import React from 'react'

import { boxxActivities, planCyberSafe, planEnhanced, planEssentials, planSaferDevice, planSaferHomes, urlPlanCyberSafe, urlPlanEnhanced, urlPlanEssentials, urlPlanSaferDevice, urlPlanSaferHomes } from '../../helpers/constants'
import BoxxActivitiesCard from './boxx-activities-card';
import { localStorageUtils } from '../../helpers/storageHelper';

const BoxxaActivities = ({ user }) => {

    const url_plan = localStorageUtils.getLocalStorageItem('url_plan');
    let premium = null
    let policyPremium = []
    let count = 0

    if (url_plan) {
        switch (url_plan) {
            case urlPlanEssentials:
                premium = planEssentials
                break;
            case urlPlanCyberSafe:
                premium = planCyberSafe
                break;
            case urlPlanEnhanced:
                premium = planEnhanced
                break;
            case urlPlanSaferHomes:
                premium = planSaferHomes
                break;
            case urlPlanSaferDevice:
                premium = planSaferDevice
                break;
            default:
                premium = null
                break;
        }
    }

    if (premium && user && user.policy) {
        policyPremium = user.policy.filter((policy) => policy.premium === premium);
        if (policyPremium.length) {
            policyPremium = policyPremium[0];
        }
    }
    if (user && user.policy && !policyPremium.length && !policyPremium.premium) {
        policyPremium = user.policy.reduce((maxPolicy, policy) => {
            const premiumValue = parseInt(policy.premium, 10);

            if (premiumValue > maxPolicy.premiumValue) {
                return {
                    premiumValue: premiumValue,
                    policyObject: policy
                };
            } else {
                return maxPolicy;
            }
        }, { premiumValue: -1, policyObject: null }).policyObject;
    }
    if (user && user.policy && policyPremium && policyPremium.premium) {
        count = Object.values(boxxActivities).filter(activity => activity.includedPolicy.includes(policyPremium.premium))
    }

    return (
        <div className='w-full flex justify-center'>
            <div className='w-full flex justify-center md:w-11/12 lg:w-3/4'>  
                <div className={`flex flex-wrap justify-center gap-x-7 gap-y-6 md:gap-x-20 md:gap-y-16`}>
                    {
                        Object.entries(boxxActivities).map((activity)=>{
                            if (user && user.policy && user.policy && Array.isArray(activity[1].includedPolicy) && activity[1].includedPolicy.includes(policyPremium.premium)) {
                                const customWidth = (count.length === 1) ? '70' : '46';
                            return(
                                <BoxxActivitiesCard
                                    key={activity[0]}
                                    titleSmall={activity[1].title_small}
                                    titleLarge={activity[1].title_large}
                                    description={activity[1].description}
                                    imgSmall={activity[1].image_small}
                                    imgLarge={activity[1].image_large}
                                    pageUrl={activity[1].page_url}
                                    customWidth = {customWidth}
                                />
                            )
                            }else{
                                return null;
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default BoxxaActivities