import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const HousingHeader = (props) => {
    const { hasBackBtn, logoPosition, hasBorder, containerClass, imageClass } = props;
    const navigate = useNavigate()
    return (
        <div className={`w-full`}>
            <div className={`w-full flex items-center ${containerClass ? containerClass : hasBackBtn ? `justify-between` : logoPosition === 'center' ? `justify-center` : `justify-end`} py-6 ${hasBorder && `border-b`} border-white border-opacity-20`}>
                <button onClick={() => navigate(-1)} className={`flex items-center space-x-3 text-dark-gray ${hasBackBtn ? `block` : `hidden`}`}>
                    <img src="/images/chevron-left.png" alt="uparrow" />
                    <p className='text-sm'>Back</p>
                </button>
                <div className=''>
                    <Link to={'/'}>
                        <img className={`w-36 ${imageClass ? imageClass : ''}`} src="/images/Housing.com_logo.svg" alt="housing logo" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default HousingHeader