import { saferHomesDesc, saferHomesExclusions, saferHomesFooter, saferHomesProducts } from "../../helpers/constants"

const Detail = () => {
    return (
        <div className='w-full flex justify-center font-Roboto'>
            <div className='w-full py-10 max-w-4xl border-b border-white border-opacity-20 space-y-7'>
                <div className='w-full space-y-8'>
                    <div className='w-full space-y-2'>
                        <p className='font-normal text-sm text-simple-gray leading-5'>{saferHomesDesc}</p>
                    </div>
                    {saferHomesProducts.map((product, index) => (
                        <div className='w-full space-y-4' key={index}>
                            <h2 className='font-bold text-base text-extra-dark-gray leading-6'>{product.product_name}</h2>
                            <p className='font-normal text-sm text-simple-gray leading-5' dangerouslySetInnerHTML={{ __html: product.product_description }}></p>
                            <ul className='pl-6 font-normal text-sm text-simple-gray leading-5 list-disc list-outside'>
                                {product.features.map((feature, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: feature }}></li>
                                ))}
                            </ul>
                            <p className='font-normal text-sm text-simple-gray leading-5' dangerouslySetInnerHTML={{ __html: product.help_txt }}></p>
                        </div>
                    ))}
                </div>
                <p className='font-normal text-sm text-simple-gray leading-5' dangerouslySetInnerHTML={{ __html: saferHomesFooter.footer }}></p>

                <div class="bg-blue-100 border border-blue-500 text-gray-700 px-4 py-3" role="alert">
                    <p class="font-bold">GENERAL EXCLUSIONS</p>
                    <ul className="pl-6 font-normal text-sm text-simple-gray leading-5 list-disc list-outside">
                        {saferHomesExclusions.map((exclu, index) => (
                            <div key={index} className="mb-0">
                                {Array.isArray(exclu) ? (
                                    <ul className="pl-6 font-normal text-sm text-simple-gray leading-5 list-disc list-outside">
                                        {exclu.map((subItem, subIndex) => (
                                            <li key={subIndex}>{subItem}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: exclu }}></li>
                                )}
                            </div>
                        ))}
                    </ul>
                    <p className="text-sm">Please refer to the policy wordings for detailed information on exclusions and other terms and conditions.</p>
                </div>
            </div>
        </div>
    )
}
export default Detail