import React, { useEffect } from 'react'
import HeadingText from '../../components/common/heading-text'
import { InnerHeader } from '../../components/common/inner-header'
import Details from '../../components/insurance-coverage/details'
import DeviceProtect from '../../components/device-protect/details'
import SaferHomes from '../../components/safer-homes/details'
import Footer from '../../components/insurance-coverage/footer'
import { deviceProtectTitle, insuranceCoverageTitle, planSaferDevice, planSaferDeviceMonthly, planSaferHomes, planSaferHomesMonthly, saferHomesTitle } from '../../helpers/constants'
import { localStorageUtils } from '../../helpers/storageHelper'


const InsuranceCoverage = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, [])

    const user = localStorageUtils.getLocalStorageItem('user');
    function switchInsurance() {

        let policyPremium = []
        if (user) {
            policyPremium = user.policy.reduce((maxPolicy, policy) => {
                const premiumValue = parseInt(policy.premium, 10);

                if (premiumValue > maxPolicy.premiumValue) {
                    return {
                        premiumValue: premiumValue,
                        policyObject: policy
                    };
                } else {
                    return maxPolicy;
                }
            }, { premiumValue: -1, policyObject: null }).policyObject;
        }
        if (user && user.policy && policyPremium && policyPremium.premium) {
            switch (policyPremium.premium) {
                case planSaferDevice:
                    return <><HeadingText title={deviceProtectTitle} />
                        <DeviceProtect monthly={false} />
                    </>

                case planSaferDeviceMonthly:
                    return <><HeadingText title={deviceProtectTitle} />
                        <DeviceProtect monthly={true} /></>

                case planSaferHomes:
                case planSaferHomesMonthly:
                    return <><HeadingText title={saferHomesTitle} />
                        <SaferHomes /></>

                default:
                    return <><HeadingText title={insuranceCoverageTitle} />
                        <Details /></>

            }
        }
    }
    return (
        <div className='w-full'>
            <div className='w-full'>
                <InnerHeader hasBackBtn={true} hasHomeBtn={true} />
            </div>
            {switchInsurance()}
            <Footer />
        </div>
    )
}

export default InsuranceCoverage