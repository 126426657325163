import { useEffect, useState } from "react";
import { InnerHeader } from "../../components/common/inner-header";
import HeadingText from "../../components/common/heading-text";
import { planSaferDevice, planSaferDeviceMonthly, planSaferHomes, planSaferHomesMonthly, saferHomesDesc, saferHomesTitle } from "../../helpers/constants";
import BoxxaActivities from "../../components/boxx-page/boxx-activities";
import { localStorageUtils } from "../../helpers/storageHelper";
import api from "../../api/axiosConfig";
import SideNav from "../../components/navbar/sideNav";
import { useNavigate } from "react-router-dom";

const SaferHomes = () => {
    const [isSideNav, setIsSideNav] = useState(false)

    const toggleSideNav = () => {
        setIsSideNav(!isSideNav);
    }
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = isSideNav ? "hidden" : "auto";
    }, [isSideNav])

    const [user, setUser] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                let policyPremium = null;
                const fetchedUser = localStorageUtils.getLocalStorageItem('user');
                if (!fetchedUser) {
                    const response = api.get('/user/profile', { headers: { 'Cache-Control': 'no-cache' } });
                    if (response.status === 200) {
                        const userData = response.data.data.user;
                        localStorageUtils.setLocalStorageItem('user', userData);
                        setUser(userData);

                        if (userData && userData.policy) {
                            policyPremium = userData.policy.reduce((maxPolicy, policy) => {
                                const premiumValue = parseInt(policy.premium, 10);
                                if (premiumValue > maxPolicy.premiumValue) {
                                    return {
                                        premiumValue: premiumValue,
                                        policyObject: policy
                                    };
                                } else {
                                    return maxPolicy;
                                }
                            }, { premiumValue: -1, policyObject: null }).policyObject;
                        }

                        if (policyPremium && policyPremium.premium) {
                            switch (policyPremium.premium) {
                                case planSaferHomes:
                                case planSaferHomesMonthly:
                                    break;
                                case planSaferDevice:
                                case planSaferDeviceMonthly:
                                    navigate('/device-protect');
                                    break;
                                default:
                                    navigate('/boxx');
                                    break;
                            }
                        }
                    }
                } else {
                    setUser(fetchedUser);
                    if (fetchedUser && fetchedUser.policy) {
                        policyPremium = fetchedUser.policy.reduce((maxPolicy, policy) => {
                            const premiumValue = parseInt(policy.premium, 10);
                            if (premiumValue > maxPolicy.premiumValue) {
                                return {
                                    premiumValue: premiumValue,
                                    policyObject: policy
                                };
                            } else {
                                return maxPolicy;
                            }
                        }, { premiumValue: -1, policyObject: null }).policyObject;
                    }

                    if (policyPremium && policyPremium.premium) {
                        switch (policyPremium.premium) {
                            case planSaferHomes:
                            case planSaferHomesMonthly:
                                break;
                            case planSaferDevice:
                            case planSaferDeviceMonthly:
                                navigate('/device-protect');
                                break;
                            default:
                                navigate('/boxx');
                                break;
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchData();
    }, [navigate]);

    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    return (
        <div className='w-full relative pb-10 px-0 lg:px-7'>
            <div className='block md:hidden w-full'>
                <InnerHeader hasBackBtn={false} />
            </div>
            <div className='block md:hidden'>
                <HeadingText title={saferHomesTitle} hasBorder={false} />
            </div>
            <div className='w-full md:flex hidden flex-col items-center md:space-y-10 md:py-14 md:mb-0'>
                <div className='text-extra-dark-gray md:text-4xl text-2xl font-semibold font-Gibson sm:text-center text-left'>
                    <h1>{saferHomesTitle}</h1>
                </div>
                <div className='md:text-xl.5 text-xl.5 leading-7 text-dark-gray font-Roboto sm:text-center text-left max-w-2xl lg:max-w-3xl'>
                    <p>
                        {saferHomesDesc}
                    </p>
                </div>
            </div>
            <div className='w-full md:hidden flex flex-col items-center space-y-5 pb-5 mb-10 border-b border-extra-lite-gray text-sm text-simple-gray font-normal font-Roboto'>
                <p>{saferHomesDesc}</p>
            </div>
            <BoxxaActivities user={user} />
            <SideNav
                isSideNav={isSideNav}
                toggleSideNav={toggleSideNav}
            />
        </div>
    )

}
export default SaferHomes