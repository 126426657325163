import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SideNav from '../navbar/sideNav'

export const InnerHeader = ({ hasBackBtn, hasHomeBtn, isBackToPage, handleContentChange,hasBorder }) => {
    const navigate = useNavigate()
    const [isSideNav, setIsSideNav] = useState(false)

    const toggleSideNav = () => {
        setIsSideNav(!isSideNav);
    }

    const navigateBack = () => {
        if(!isBackToPage && isBackToPage !== undefined){
            handleContentChange('','back')
        }else{
            if (hasHomeBtn) {
                navigate('/')
            } else {
                navigate(-1)
            }
        }
    }
    return (
        <div className={`w-full py-5 ${hasBorder ? 'border-b' : 'border-none'} border-extra-lite-gray`}>
            <div className={`w-full grid grid-cols-3`}>
                <button onClick={navigateBack} className={`flex items-center  space-x-3 text-medium-purple ${hasBackBtn ? `visible` : `invisible`}`}>
                    <img src="/images/chevron-up-purple.png" alt="uparrow" />
                    <p className='text-sm font-medium text-medium-purple'>{hasHomeBtn ? 'Home' : 'Back'}</p>
                </button>
                <div className='flex items-center justify-center'>
                    <div className='w-75px h-10 flex items-center justify-center md:hidden'>
                        <Link to={'/'}>
                            <img className={``} src="/images/dark/boxx.svg" alt="boxx logo" />
                        </Link>
                    </div>
                </div>
                <div className='flex items-center justify-end'>
                    <button onClick={() => toggleSideNav()} className='min-w-max visible md:invisible'>
                        <img src="/images/dark/user-icon.png" alt="user-icon" />
                    </button>
                </div>
                <SideNav
                    isSideNav={isSideNav}
                    toggleSideNav={toggleSideNav}
                />
            </div>
        </div>
    )
}
