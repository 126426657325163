import React from 'react'
import { Link } from 'react-router-dom'

const BoxxActivitiesCard = ({
    titleSmall,
    titleLarge,
    description,
    imgSmall,
    imgLarge,
    pageUrl,
    customWidth
}) => {
    let activityImgLg = `md:bg-${imgLarge}`;
    return (
        <div className={`rounded md:rounded-none border md:border-none border-dark-blue bg-light-blue md:bg-none sm:block flex flex-col items-center justify-center ${customWidth === '46' ? 'w-[46%]' : 'w-[76%]'}`} >
            <Link to={pageUrl} className="md:h-auto h-full w-full">
                <div className={`sm:bg-none ${activityImgLg} bg-cover bg-no-repeat flex flex-col items-center justify-center md:h-auto h-full w-full shadow-none md:shadow-[0px_53.8627px_53.8627px_-53.8627px_rgba(0,0,0,0.5)] md:aspect-video`}>
                    <div className={`flex flex-col items-center md:justify-center justify-between space-y-2 py-5 px-4 font-Gibson h-full`}>
                        <img src={`/images/${imgSmall}`} className="sm:block md:hidden object-contain" alt={'boxx-activity1'} />
                        <div className='w-full h-full flex items-center justify-center'>
                            <h2 className='text-dark-gray md:text-white font-Roboto font-bold md:inline-block hidden sm:w-10/12 w-11/12 text-center md:text-2xl xl:text-35px text-xl leading-8'><span>{titleLarge}</span></h2>
                            <h2 className='text-dark-gray md:text-white font-Roboto font-bold inline-block md:hidden sm:w-10/12 w-11/12 text-center md:text-2xl xl:text-35px text-xl leading-8 '><span>{titleSmall}</span></h2>
                        </div>
                    </div>
                </div>
            </Link>
            <div className='hidden md:block pt-5 lg:pt-7'>
                <p className='text-center font-normal md:text-xs lg:text-xs  xl:text-base 2xl:text-lg text-dark-gray font-Roboto leading-6'>{description}</p>
            </div>
        </div>
    )
}

export default BoxxActivitiesCard