import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './components/layouts/layout';
import Login from './pages/login';
import PageNotFound from './pages/page_not_found';
import DigitalSafety from './pages/cyber_training/digital-safety';
import OnlineRisks from './pages/cyber_training/online-risks';
import SensitiveData from './pages/cyber_training/sensitive-data';
import Boxx from './pages/boxx';
import CyberTraining from './pages/cyber_training';
import InsuranceCoverage from './pages/insurance_coverage';
import DigitalRiskAssessment from './pages/digital_risk_assessment';
import PrivateRoute from './hoc-auth/PrivateRoute';
import CheckBreachesResults from './pages/check_for_breaches/results';
import CheckBreachesDetails from './pages/check_for_breaches/details';
import CheckForBreaches from './pages/check_for_breaches';
import CyberSecurity from './pages/cyber_training/cyber-security';
import AssessmentPg from './pages/digital_risk_assessment/assessment';
import SubscribedRoute from './hoc-auth/SubscribedRoute';
import DeviceProtect from './pages/device_protect';
import { useEffect } from 'react';
import { localStorageUtils } from './helpers/storageHelper';
import SaferHomes from './pages/safer_homes';

function App() {

  useEffect(() => {
    const clearLocalStorageOnClose = () => {
      localStorageUtils.removeLocalStorageItem('url_plan');
    };

    window.addEventListener('beforeunload', clearLocalStorageOnClose)

    return () => {
      window.removeEventListener('beforeunload', clearLocalStorageOnClose);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path='/boxx' element={<PrivateRoute><Boxx /></PrivateRoute>} />
          <Route path='/check_for_breaches' element={<PrivateRoute><SubscribedRoute><CheckForBreaches /></SubscribedRoute></PrivateRoute>} />
          <Route path='/check_for_breaches/results' element={<PrivateRoute><SubscribedRoute><CheckBreachesResults /></SubscribedRoute></PrivateRoute>} />
          <Route path='/check_for_breaches/results/detail' element={<PrivateRoute><SubscribedRoute><CheckBreachesDetails /></SubscribedRoute></PrivateRoute>} />
          <Route path='/cyber_training' element={<PrivateRoute><SubscribedRoute><CyberTraining /></SubscribedRoute></PrivateRoute>} />
          <Route path='/cyber_training/digital-safety' element={<PrivateRoute><SubscribedRoute><DigitalSafety /></SubscribedRoute></PrivateRoute>} />
          <Route path='/cyber_training/online-risks' element={<PrivateRoute><SubscribedRoute><OnlineRisks /></SubscribedRoute></PrivateRoute>} />
          <Route path='/cyber_training/sensitive-data' element={<PrivateRoute><SubscribedRoute><SensitiveData /></SubscribedRoute></PrivateRoute>} />
          <Route path='/cyber_training/cyber-security' element={<PrivateRoute><SubscribedRoute><CyberSecurity /></SubscribedRoute></PrivateRoute>} />
          <Route path='/insurance_coverage' element={<PrivateRoute><SubscribedRoute><InsuranceCoverage /></SubscribedRoute></PrivateRoute>} />
          <Route path='/digital_risk_assessment' element={<PrivateRoute><SubscribedRoute><DigitalRiskAssessment /></SubscribedRoute></PrivateRoute>} />
          <Route path='/digital_risk_assessment/assessment' element={<PrivateRoute><SubscribedRoute><AssessmentPg /></SubscribedRoute></PrivateRoute>} />
          <Route path='/device-protect' element={<PrivateRoute><DeviceProtect /></PrivateRoute>} />
          <Route path='/safer-homes' element={<PrivateRoute><SaferHomes /></PrivateRoute>} />
          <Route path='/device-protect-coverage' element={<PrivateRoute><SubscribedRoute><InsuranceCoverage /></SubscribedRoute></PrivateRoute>} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
