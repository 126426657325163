import React from 'react'
import { InsuranceCoverageBajajAllianzLinkText, InsuranceCoverageBajajAllianzPageLink, planSaferDevice, planSaferDeviceMonthly, planSaferHomes, planSaferHomesMonthly } from '../../helpers/constants'
import { localStorageUtils } from '../../helpers/storageHelper';

const Footer = () => {

    const user = localStorageUtils.getLocalStorageItem('user');
    let policyPremium = []
    let footerLogo = '/images/bajajallianz_logo.webp';
    let footerLogoAlt = 'bajajallianz logo';
    let footerLink = InsuranceCoverageBajajAllianzPageLink
    let footerTitle = InsuranceCoverageBajajAllianzLinkText


    if (user) {
        policyPremium = user.policy.reduce((maxPolicy, policy) => {
            const premiumValue = parseInt(policy.premium, 10);

            if (premiumValue > maxPolicy.premiumValue) {
                return {
                    premiumValue: premiumValue,
                    policyObject: policy
                };
            } else {
                return maxPolicy;
            }
        }, { premiumValue: -1, policyObject: null }).policyObject;
    }
    if (user && user.policy && policyPremium && policyPremium.premium) {
        switch (policyPremium.premium) {
            case planSaferDevice:
            case planSaferDeviceMonthly:
                footerLogo = '/images/across-assist.png';
                footerLogoAlt = 'across assist logo';
                footerLink = 'https://www.acrossassist.com/'
                footerTitle = 'Across Assist'
                break;

            case planSaferHomes:
            case planSaferHomesMonthly:
                footerLogo = '/images/Bagic-New-Logo-White-Blue_Bg_R1.webp';
                footerLogoAlt = 'bajajallianz logo';
                footerLink = InsuranceCoverageBajajAllianzPageLink
                footerTitle = InsuranceCoverageBajajAllianzLinkText
                break;

            default:
                break;

        }
    }

    return (
        <div className='w-full flex justify-center'>
            <div className='w-full py-5 max-w-4xl space-y-5'>
                <div className=''>
                    <img src={footerLogo} alt={footerLogoAlt} className='w-40' />
                </div>
                <div className='space-y-3'>
                    <div>
                        <a href={footerLink} target="_blank" rel="noreferrer">
                            <div className='flex items-center space-x-3 '>
                                <h1 className='text-sm text-simple-gray font-normal font-Roboto'>{footerTitle}</h1>
                                <img src="/images/arrow-right-circle-small_v1.svg" alt="arrow"  />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer