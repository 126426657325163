////---------common start--------////

export const formPopupValidationMsg = 'Please choose at least one'
export const defaultConnectionErrorMsg = 'There was a problem trying to connect to the server. Please try again'
export const termsAndConditionLink = 'http://boxxinsurance.com/boxxcybersafe-terms/'
export const privacypolicyLink = 'https://boxxinsurance.com/privacypolicy/'
export const boxxinsuranceLink = 'https://www.boxxinsurance.com/'
export const termsAndConditionText = 'Terms & Conditions'
export const privacypolicyText = 'Privacy Policy'
export const boxxinsuranceText = 'boxxinsurance.com'
export const footerTitle = 'PREDICT. PREVENT. INSURE.'
export const footerDescription = 'All-in-one cyber insurance and protection that reduces risk'
////---------common end--------////


////---------DRA start--------////

export const draAssessment = {
    1: {
        questions: {
            1: {
                question: 'Please select all the digital devices that you and your family regularly use:',
                answerTypes: {
                    1: {
                        answerType: 'checkbox',
                        options: {
                            1: {
                                name: "iPhone",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            2: {
                                name: "Android Mobile",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            3: {
                                name: "Laptop / Desktop ",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            4: {
                                name: "MacBook",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            5: {
                                name: "Tablet",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            6: {
                                name: "Smart Speakers (including virtual assistants like Alexa, Siri, or Google Assistant)",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            7: {
                                name: "Smart Home Products (i.e. TV, washing machine, refrigerators, etc.)",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            8: {
                                name: "Business Phone or Laptop",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            9: {
                                name: "Security Cameras or Door Locks",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                        },
                    }
                },
                advice: `Cyber criminals can access your data and systems. To reduce the digital risks facing you and your family, it's important to understand how you can protect yourself. That's why we recommend that you always set up additional privacy and security features like strong unique passwords, MFA and use biometrics on your devices to decrease the risk of unauthorized access.`
            },
        },
        title: ``
    },
    2: {
        questions: {
            1: {
                question: 'What are the online services that you and your family regularly use? Select all that apply:',
                answerTypes: {
                    1: {
                        answerType: 'checkbox',
                        options: {
                            1: {
                                name: "Gmail (Email)",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            2: {
                                name: "Hotmail or Yahoo Mail (Email)",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            3: {
                                name: "Other Email service",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            4: {
                                name: "Facebook, Instagram, Twitter, TikTok or any other social network",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            5: {
                                name: "Streaming Services",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            6: {
                                name: "Banking",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            7: {
                                name: "Apps or Streaming Services for Kids",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            8: {
                                name: "Online Gaming",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                        },
                    }
                },
                advice: `Our life has increasingly become more digital as most services like online banking, shopping and streaming have become ubiquitous. When using these services, it's important to be informed about their potential risks and to remain vigilant when see any warning signs so that you don't become the victim of Ransomware, Phishing, Scams, Fraud and more.`
            },
        },
        title: ``
    },
    3: {
        questions: {
            1: {
                question: 'How do you protect your personal data at home? Select all that apply:',
                answerTypes: {
                    1: {
                        answerType: 'checkbox',
                        options: {
                            1: {
                                name: "My data backup is on a hard drive permanently connected to my laptop / desktop",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            2: {
                                name: "My data backup is on a hard drive that I connect my laptop / desktop only when I want to conduct a backup",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            3: {
                                name: "My data backup is fully sincronyzed with a cloud backup service",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            4: {
                                name: "I don`t backup my data",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            5: {
                                name: "I enable MFA (Multifactor Authentication) in all online services",
                                marks: {
                                    selected: 4,
                                    blank: 1
                                },
                            },
                        },
                    }
                },
                advice: `Have you thought about what could happen if you lost your data? To minimize this risk, it's key to have a good backup in place (eg. a hard drive, a paid 3rd party service etc.) that is regularly updated and secure which is protected by MFA.`
            },
        },
        title: ``
    },
    4: {
        questions: {
            1: {
                question: 'Please select all the security measures that you take at home to protect your privacy:',
                answerTypes: {
                    1: {
                        answerType: 'checkbox',
                        options: {
                            1: {
                                name: "I use a VPN (Virtual Private Network) when I need to connect to public Wi-Fi",
                                marks: {
                                    selected: 3,
                                    blank: 1
                                },
                            },
                            2: {
                                name: "Share everything I do with your contacts, and accept all friends requests",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            3: {
                                name: "I use social media, but I just accept invitation from closed friends and share only non-sensitive information",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            4: {
                                name: "I manage privacy settings of social networks to control my visibility",
                                marks: {
                                    selected: 3,
                                    blank: 1
                                },
                            },
                            5: {
                                name: "My data is not important, so I don`t take any measures to protect it",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                        },
                    }
                },
                advice: `You most likely lock your door and windows at night to ensure you and your family's personal privacy and safety. But most people don't replicate the same measures when they're online. That's why we always recommend that you use a private VPN to ensure your online activities remain private at home or on a public network. It's also good practice to set up a private browsing feature and to regularly remove personal information that's online and purge old contacts from your social profiles.`
            },
        },
        title: ``
    },
    5: {
        questions: {
            1: {
                question: 'Please select all the measures you and your family use at home to protect your home network and devices:',
                answerTypes: {
                    1: {
                        answerType: 'radio',
                        options: {
                            1: {
                                name: "I use a free antivirus",
                                marks: {
                                    selected: 2,
                                    blank: 1
                                },
                                relatedAnswers: [2]
                            },
                            2: {
                                name: "I use a licensed antivirus",
                                marks: {
                                    selected: 3,
                                    blank: 1
                                },
                                relatedAnswers: [1]
                            },
                        },
                    },
                    2: {
                        answerType: 'radio',
                        options: {
                            1: {
                                name: "I patch the operating system (Windows, iOS, etc.) once the update is released",
                                marks: {
                                    selected: 3,
                                    blank: 1
                                },
                                relatedAnswers: [4]
                            },
                            2: {
                                name: "I don´t patch often my systems (i.e. operating system, antivirus, etc.)",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                                relatedAnswers: [3]
                            },
                        },
                    },
                    3: {
                        answerType: 'checkbox',
                        options: {
                            1: {
                                name: "I use different sub-networks at home (i.e. Guest Wi-fi network)",
                                marks: {
                                    selected: 4,
                                    blank: 1
                                },
                            },
                            2: {
                                name: "I have changed my router´s default password",
                                marks: {
                                    selected: 3,
                                    blank: 1
                                },
                            },
                            3: {
                                name: "I use a password management to create and manage passwords",
                                marks: {
                                    selected: 3,
                                    blank: 1
                                },
                            },
                        },
                    },
                },
                advice: `Protecting your devices and systems is critical to ensure your online safety. That's why we recommend that you create strong unique passwords for all your accounts, set up MFA where possible and keep yourself regularly informed of common digital risks and scams like phishing, SMSishing and more.`
            },
        },
        title: ``
    },
    6: {
        questions: {
            1: {
                question: `If you are browsing the internet, and you see an ad for a great app that has incredible features, would you download the app?`,
                answerTypes: {
                    1: {
                        answerType: 'select',
                        options: {
                            1: {
                                name: "Sure!",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            2: {
                                name: "No, it might be a risk for me",
                                marks: {
                                    selected: 0,
                                    blank: 0
                                },
                            },
                        },
                    }
                },
                advice: `Staying informed and aware of common digital risks is your best defense against cyber criminals to protect you and your family's privacy, financial autonomy and well-being.`
            },
            2: {
                question: `If you are registering for a new online service, would you reuse the same password you use for other services`,
                answerTypes: {
                    1: {
                        answerType: 'select',
                        options: {
                            1: {
                                name: "Sure, it´s the easiest",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            2: {
                                name: "No, I use my password manager",
                                marks: {
                                    selected: 0,
                                    blank: 0
                                },
                            },
                            3: {
                                name: "No, I create myself a different one",
                                marks: {
                                    selected: 1,
                                    blank: 0
                                },
                            },
                            4: {
                                name: "I use Google´s, Facebook´s or Apple´s account for login",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                        },
                    }
                },
                advice: `Staying informed and aware of common digital risks is your best defense against cyber criminals to protect you and your family's privacy, financial autonomy and well-being.`
            },
            3: {
                question: `When accessing a new website, do you consent to collect all kind of cookies?`,
                answerTypes: {
                    1: {
                        answerType: 'select',
                        options: {
                            1: {
                                name: "Sure, it´s the easiest",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            2: {
                                name: "No, only the necessary ones",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            3: {
                                name: "I try to avoid ",
                                marks: {
                                    selected: 1,
                                    blank: 0
                                },
                            },
                        },
                    }
                },
                advice: `Staying informed and aware of common digital risks is your best defense against cyber criminals to protect you and your family's privacy, financial autonomy and well-being.`
            },
            4: {
                question: `I allow my kids to use the tablet, phone or Smart TV freely`,
                answerTypes: {
                    1: {
                        answerType: 'select',
                        options: {
                            1: {
                                name: "Sure, I trust them",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            2: {
                                name: "No, I limit the time the spend on devices",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            3: {
                                name: "Sure, but I inform them about the risk they take",
                                marks: {
                                    selected: 2,
                                    blank: 0
                                },
                            },
                            4: {
                                name: "I use parental control to limit time, apps and sites",
                                marks: {
                                    selected: 1,
                                    blank: 0
                                },
                            },
                            5: {
                                name: "I don´t have kids",
                                marks: {
                                    selected: 0,
                                    blank: 0
                                },
                            },
                        },
                    }
                },
                advice: `Staying informed and aware of common digital risks is your best defense against cyber criminals to protect you and your family's privacy, financial autonomy and well-being.`
            },
            5: {
                question: `If you receive an email with a 24 hours 80% discount for that mobile you want to buy from your favourite store, what would you do?`,
                answerTypes: {
                    1: {
                        answerType: 'select',
                        options: {
                            1: {
                                name: "Ask for a friend´s opinion",
                                marks: {
                                    selected: 0,
                                    blank: 0
                                },
                            },
                            2: {
                                name: "Check sender´s domain name that is matches the website of your favourite store",
                                marks: {
                                    selected: 3,
                                    blank: 0
                                },
                            },
                            3: {
                                name: "Follow the instructions and pay for it!",
                                marks: {
                                    selected: 0,
                                    blank: 0
                                },
                            },
                            4: {
                                name: "Check sender´s domain name that is matches the website of your favourite store, and call them just in case.",
                                marks: {
                                    selected: 4,
                                    blank: 0
                                },
                            },
                        },
                    }
                },
                advice: `Staying informed and aware of common digital risks is your best defense against cyber criminals to protect you and your family's privacy, financial autonomy and well-being.`
            },
        },
        title: `Here's some online scenario activities. How would you and your family act if you encounter any of the following:`
    },
}

export const draGrandTotal = 110;
export const draScoreStaringRange = 0;

export const draScoreChartOptions = {
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    stroke: {
        show: false,
    },
    tooltip: {
        enabled: false,
    },
    plotOptions: {
        pie: {
            startAngle: -135,
            endAngle: 135,
            offsetY: -9,
            offsetX: -49,
            donut: {
                size: '80%'
            }
        },
    },
    colors: ['#CA3434', '#FCDA97', '#63C48A'],
    states: {
        hover: {
            filter: {
                type: 'none',
            }
        },
    }

}
export const draResultChartOptions = {
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    stroke: {
        show: false,
    },
    tooltip: {
        enabled: false,
    },
    plotOptions: {
        pie: {
            startAngle: -135,
            endAngle: 135,
            offsetY: -8,
            offsetX: -37,
            donut: {
                size: '80%'
            }
        }
    },
    colors: ['#CA3434', '#FCDA97', '#63C48A'],
    states: {
        hover: {
            filter: {
                type: 'none',
            }
        },
    }

}
export const draChartData = [46, 30, 25]

export const draScoreChartWidth = 320;
export const draResultChartWidth = 200;
export const draPageTitle = 'Digital Risk Assessment';
export const draPageIntoBtnText = 'Get started';
export const draPageIntoTitle = 'Understand how your online habits may be compromising your personal safety online';
export const draPageIntoDescription = 'Our quick assessment will help you understand how your online behaviors can make you vulnerable to a cyberattack. We’ll assess your digital vulnerabilities and where you can improve to ensure your personal information isn’t compromised when you’re online.';
export const draInvalidSectionMsg = 'Oops! something went wrong';
export const draPreviousBtnText = 'Previous';
export const draNextBtnText = 'Save and continue';
//////////////////-dra-assessement-score-data-//////////////////////////////////
export const draScoreSectionTitle = 'Your score is now ready!'
export const draScoreSectionTitleDesription = 'Thank you for completing!'
export const draScoreSectionDescription = [
    `Your score is calculated based on the information you've provided in the survey and if your personal information has been exposed by any security breaches`,
    `If you are concerned about your score, we recommend that you take part in the BOXX Cyber Training modules to improve your cyber security and awareness knowledge.`
]
export const draScoreSectionLinkText = 'Major factors for determining  your score'
//////////////////-dra-assessement-result-data-//////////////////////////////////
export const draResultSectionTitle = 'Your Score Results'
export const draResultSectionTitleDescription = 'We provide a score based on major cyber security factors that may indicate that your personal information is at risk.'
export const draResultClasification = {
    1: {
        title: `Poor (0-49)`,
        description: `The likelihood of you experiencing a personal security breach or a cyber incident is extremely high. We recommend that you develop a cyber security strategy as soon as possible.`,
        type: 'poor'
    },
    2: {
        title: `Good (50-84)`,
        description: `You seem to be cyber aware but can definitely make some improvements when it comes to safeguarding your personal information to prevent a cyber attack.`,
        type: 'good'
    },
    3: {
        title: `Great (85-110)`,
        description: `You're knowledgeable about cyber security and have taken proactive measures to protect your personal information to decrease risks around a cyber attack. Keep staying up to date on the latest security protocols and measures to protect your personal data.`,
        type: 'great'
    }
}
export const draResultSectionLinkText = 'How we determined your score'

////---------DRA end--------////

////---------Cyber Safety training start--------////
export const cyberReport = {
    1: {
        title: 'Data Breach',
        description: 'A data breach is a confirmed incident in which sensitive, confidential, or otherwise protected data has been accessed and/or disclosed in an unauthorized fashion. Data breaches may involve personal health information (PHI), personally identifiable information (PII), trade secrets or intellectual property'
    },
    2: {
        title: 'Insider Threat',
        description: 'Insider threat is a term for a threat to an organization’s security or data that comes from within. Such threats are usually attributed to employees or former employees, but may also arise from third parties, including contractors, temporary workers, or customers.'
    },
    3: {
        title: 'Privacy Regulation',
        description: 'Company handles personal information it collects, uses or discloses from the general public and its own employees. Non-compliance could result in organizational reputational risks and financial penalties.'
    },
    4: {
        title: 'Malware Attack',
        description: 'Malware attacks have been carried out by diverse threat actors, ranging from individual criminal hackers to organized crime rings and government agencies. Malware attacks can occur due to poor web development, missing patches or unstable network systems, and they can cause extreme damage to company systems and its infrastructure.'
    },
    5: {
        title: 'E-Theft',
        description: 'E-Theft or E-Fraud, also known as CEO-Fraud or Whaling, is an incident where highly targeted communications are used to transfer funds or provide sensitive information. The communications generally pretend to be from a person of authority (e.g. CEO or CFO). E-theft incidents have recently expanded beyond emails to use also phone calls and SMS.'
    },
    6: {
        title: 'Software Vulnerabilities',
        description: 'A software vulnerability is a weakness in a product that could allow an attacker to compromise the integrity, availability, or confidentiality of that software implementation.'
    },
}
export const cyberSafetyDataList = {
    1: {
        header: "Digital Safety",
        image: "digital-safety.png",
        description: "Learn the basics of what it takes to remain safe when you’re online for personal or business use. ",
        path: "digital-safety"
    },
    2: {
        header: "Managing Online Risks",
        image: "online-risks.png",
        description: "Learn the tactics that cyber criminals are using to target you while you’re online through social engineering attacks like phishing. ",
        path: "online-risks"
    },
    3: {
        header: "Protecting Sensitive Data",
        image: "sensitive-data.png",
        description: "Learn what type of information is considered sensitive – and how to protect it whether it’s online or on paper. ",
        path: "sensitive-data"
    },
    4: {
        header: "Cybersecurity on the Go ",
        image: "cyber-security.png",
        description: "Learn why being connected on the go can make you more vulnerable to cyberattacks, and how you can reduce online risks.",
        path: "cyber-security"
    },
}
export const cyberSafetyTitle = 'Cyber Safety & Awareness Training'
export const cyberSafetyCardBtnText = 'Begin Module'
export const cyberSafetyDescription = 'Raise your cyber security awareness – and decrease your risk of being victimized by cybercrime. BOXX’s cyber training modules will give you the educational tools to prevent and detect cybercrime in order to keep your personal information safe while you’re online.'
export const cyberSafetyCommonModlueDetails = 'This module includes an educational video, downloadable information resources and an assessment (15 questions).'
export const cyberSafetySectionHandleErrMsg = 'Oops! something went wrong'
export const cyberSafetyIntroSectionBtnText = 'Continue'
export const cyberSafetyELearningSectionBtnText = 'Continue to Assessment'
export const cyberSafetyIntroSectionTitle = 'Introduction'
export const cyberSafetyELearningSectionTitle = 'e-Learning Material'
export const cyberSafetyAssessmentPrevBtnText = 'Previous'
export const cyberSafetyAssessmentSubmitBtnText = 'Submit Assessment'
export const cyberSafetyAssessmentNextBtnText = 'Next page'
export const cyberSafetyAssessmentHeadTitle = 'Assessment'
export const cyberSafetyAssessmentCompletedMsg = 'You’ve completed the Assessment!'
export const cyberSafetyAssessmentCompletedSubMsg = 'Continue to submit assessment and see your results'
export const cyberSafetyAssessmentResultLoadingMsg = 'Verifying your answers...'
export const cyberSafetyAssessmentTryagainMsg = 'Please try again!'
export const cyberSafetyAssessmentRestartBtnText = 'Restart assessment'
export const cyberSafetyAssessmentBackToTrainingBtnText = 'Back to Cyber training'

////---------Cyber Safety training end--------////

////---------Home page(/boxx) start--------////

export const homePageTitle = 'Welcome to BOXX CyberSafe';
export const homePageTitleDescription = 'Predict digital threats, prevent online scams, and be insured for unauthorized digital financial transactions. We help you stay digitally safe and protected online.';
export const planMonitoring = process.env.REACT_APP_PLAN_MONITORING || "19";
export const planClassic = process.env.REACT_APP_PLAN_CLASSIC || "39";
export const planBase = process.env.REACT_APP_PLAN_BASE || "49";
export const planEssentials = process.env.REACT_APP_PLAN_ESSENTIALS || "69";
export const planCyberSafe = process.env.REACT_APP_PLAN_CYBERSAFE || "99";
export const planEnhanced = process.env.REACT_APP_PLAN_ENHANCED || "199";
export const planSaferHomes = process.env.REACT_APP_PLAN_SAFER_HOMES || "1941.10";
export const planSaferDevice = process.env.REACT_APP_PLAN_SAFER_DEVICE || "1335.76";
export const planSaferHomesMonthly = process.env.REACT_APP_PLAN_SAFER_HOMES_MONTH || "251.97";
export const planSaferDeviceMonthly = process.env.REACT_APP_PLAN_SAFER_DEVICE_MONTH || "336.54";
export const urlPlanEssentials = process.env.REACT_APP_URL_PLAN_ESSENTIALS || "Essentials";
export const urlPlanCyberSafe = process.env.REACT_APP_URL_PLAN_CYBERSAFE || "CyberSafe";
export const urlPlanEnhanced = process.env.REACT_APP_URL_PLAN_ENHANCED || "Enhanced";
export const urlPlanSaferHomes = process.env.REACT_APP_URL_PLAN_SAFER_HOMES || "SaferHomes";
export const urlPlanSaferDevice = process.env.REACT_APP_URL_PLAN_SAFER_DEVICE || "SaferDevices";
export const urlPlanSaferHomesMonthly = process.env.REACT_APP_URL_PLAN_SAFER_HOMES_MONTH || "SaferHomesMonthly";
export const urlPlanSaferDeviceMonthly = process.env.REACT_APP_URL_PLAN_SAFER_DEVICE_MONTH || "SaferDevicesMonthly";

export const boxxActivities = {
    1: {
        title_small: 'Digital Risk Assessment',
        title_large: 'Digital Risk Assessment',
        image_small: 'search.png',
        image_large: 'home_dra',
        description: 'Understand how your online habits may be compromising your personal safety online.',
        page_url: '/digital_risk_assessment',
        includedPolicy: [planCyberSafe, planEnhanced]
    },
    2: {
        title_small: 'Identity Monitoring',
        title_large: 'Identity Monitoring',
        image_small: 'eye.png',
        image_large: 'home_cfb',
        description: 'Protect your identity by checking if your credentials have been exposed in a cyber breach with dark web monitoring.',
        page_url: '/check_for_breaches',
        includedPolicy: [planMonitoring, planClassic, planBase, planEssentials, planCyberSafe, planEnhanced, planSaferDevice, planSaferHomes, planSaferHomesMonthly, planSaferDeviceMonthly]
    },
    3: {
        title_small: 'Cyber Safety Training',
        title_large: 'Cyber Safety & Awareness Training',
        image_small: 'training 2.png',
        image_large: 'home_ct',
        description: 'Our award-winning training will raise your cyber security awareness – and decrease your risk of being victimized by cybercrime.',
        page_url: '/cyber_training',
        includedPolicy: [planCyberSafe, planClassic, planBase, planEssentials, planEnhanced, planSaferDevice, planSaferHomes, planSaferHomesMonthly, planSaferDeviceMonthly]
    },
    4: {
        title_small: 'Insurance Coverage',
        title_large: 'Insurance Coverage',
        image_small: 'shield.png',
        image_large: 'home_ic',
        description: 'Insurance to protect you from unauthorized digital financial transactions.',
        page_url: '/insurance_coverage',
        includedPolicy: [planCyberSafe,  planClassic, planBase, planEssentials, planEnhanced, planSaferHomes, planSaferHomesMonthly]
    },
    5: {
        title_small: 'Device Protect',
        title_large: 'Device Protect',
        image_small: 'shield.png',
        image_large: 'home_ic',
        description: 'Your ‘Device Protect’ package provides a cyber protection tool to keep you digitally safe and also provides your smartphones, tablets protects against any damage to the screens.',
        page_url: '/device-protect-coverage',
        includedPolicy: [planSaferDevice, planSaferDeviceMonthly]
    }
}

////---------Home page(/boxx) end--------////


////---------Login page(/boxx) start--------////
export const loginPageTitle = {
    message: 'Sign in to your',
    product: 'BOXX CyberSafe Account'
}
export const loginPageSubTitle = 'Enter your phone number to sign in:'
export const loginPageT_and_C_message = 'I agree with the BOXX Terms & Conditions'
export const loginPageInputTitle = 'Phone number'
export const loginPageInputPlaceholder = 'Enter phone number'
export const loginBtnText = 'Sign in'
export const loginInputValidationErrorText = 'Enter a valid phone number'
export const loginInputCustomValidationText = 'Please provide a valid phone number'
////---------Login page(/boxx) end--------////

////---------Check for breaches start--------////

export const breachesParaOne = 'Cyber security breaches are becoming increasingly common – in 2022, at least 22 billion records were exposed due to data breaches. As a result, personal information like your email address, phone numbers and other personal data, even passwords, may end up on the dark web for cyber criminals to target you via social engineering or ransomware attacks.';
export const breachesParaTwo = 'Use our Identity Monitoring tool to find out if your personal information has been compromised in a breach – and what information was exposed.';
export const breachesIntroduction = {
    1: 'Cyber security breaches are becoming increasingly common – in 2022, at least 22 billion records were exposed due to data breaches. As a result, personal information like your email address, phone numbers and other personal data, even passwords, may end up on the dark web for cyber criminals to target you via social engineering or ransomware attacks.',
    2: 'Use our Identity Monitoring tool to find out if your personal information has been compromised in a breach – and what information was exposed.'
}
export const breachesSearchInputPlaceHolder = 'Enter your email or phone number'
export const breachesSearchInputCustValidationMsg = 'Please provide either a valid phone number or email address'
export const breachesSearchBtnText = 'Search'
export const breachesTitle = 'Identity Monitoring'

////---------Check for breaches end--------////


////---------Breache result start--------////

export const breacheResultCardDateLabel = 'Breach added:'
export const breacheResultCardCompremisedDataLabel = 'Compromised data:'
export const breacheResultCardBtnText = 'HOW TO PROTECT YOUR DATA'
export const breachContents = {
    1: {
        title: "Change & Update Your Passwords",
        description: "Create passwords for all your accounts with at least 12 characters using uppercase and lowercase letters, numbers, special characters and spaces. Use a password management tool to safely store them and to get alerts if they’ve been compromised. "
    },
    2: {
        title: "Update Any Logins that Re-Use Your Passwords",
        description: "Reusing passwords can give cyber criminals password access to multiple accounts. Now that this password is out there, it's important to change it for all your accounts. "
    },
    3: {
        title: "Set up Multi-Factor Authentication (MFA) ",
        description: "MFA requires multiple methods of authentication to verify your identity to login to an account or perform a transaction. Most services offer this feature and it will keep your information secure. "
    },
    4: {
        title: "Close Old Accounts ",
        description: "If you have online accounts that you no longer use, you should delete them as well as any financial information within them. "
    },
    5: {
        title: "Stay Informed About Online Risks and Cyber Threats ",
        description: `Staying up to date on data breaches as they are unfortunately happening all the time. Local and national news programs regularly cover cyber and consumer safety events in their segments and you can regularly visit <a href='https://www.csk.gov.in/alerts.html' target={'_blank'} rel="noreferrer" style="text-decoration: underline">https://www.csk.gov.in/alerts.html</a> to stay up to date on local alerts.`
    },
}
export const breachPopupTitle = 'Your personal credentials have been leaked in a data breach. You should take the following actions immediately:'
export const breachPopupCloseBtnText = 'Back'

////---------Breache result end--------////

////---------Insurance coverage start--------////

export const insuranceCoverageTitle = 'Insurance Coverage'
export const insuranceCoverageIntro = 'Please refer to the policy for detailed information’s on exclusions and other Terms & Conditions'
export const insuranceCoverageAmountDetails = 'Unauthorized Digital Financial Transaction Coverage up to '
export const insuranceCoverageInstrumentsCovered = 'Instruments covered- Credit Card, UPI, Debit Card, Net-banking, Wallets'
export const insuranceCoverageFinancialLossesDetails = 'Insured would be covered by any financial losses due to Unauthorized Electronic transaction from Insured’s bank account to a Third Party account consequent upon Identity Theft, arising out of the below perils:'
export const InsuranceCoverageDifferentTypesOfAttack = {
    1: 'Cyber Attack(s)',
    2: 'Phishing/Spoofing emails and/or messages and/or links and or telephone calls',
    3: 'simjacking'
}
export const InsuranceCoverageBajajAllianzTitle = 'Digital Suraksha covers:'
export const InsuranceCoverageWhatBajajAllianzCovers = {
    1: {
        title: 'Unauthorized Digital Financial Transaction Cover',
    },
    2: {
        title: 'Insured would be covered for any financial losses due to: Unauthorized Electronic transaction from Insured’s bank account to a Third Party account consequent upon Identity Theft, arising out of below perils:',
        list: {
            1: 'Cyber Attack(s)',
            2: 'Phishing/Spoofing E-Mail and/or Messages and/or Links and/or telephonic calls',
            3: 'Simjacking'
        }
    }
}
export const InsuranceCoverageMajorExclusions = {
    1: {
        title: 'Major Exclusions',
        exclusions: {
            1: 'Any transaction involving physical money/cash/currency or currency equivalent, including withdrawal of money from Automated Teller Machine (A.T.M)',
            2: 'Direct Financial Loss as a result of physical loss/theft of card, phone, laptop or any other device',
            3: 'Gross negligence in taking precautions to safeguard Insured Beneficiary’s Personal Data',
            4: 'Cyber Extortion Loss',
            5: 'Trading- Any losses or liabilities connected with any types of purchase or sale transactions or other dealing in securities, commodities, derivatives, foreign, or Federal Funds, currencies, foreign exchange, currency swap, crypto currencies, non-fiat currencies and the like',
            6: 'Any Direct Financial Loss to Insured Beneficiary that are caused by Insured Beneficiary intentionally & against the law',
        }
    }
}
export const InsuranceCoverageBajajAllianzPageLink = 'https://www.bajajallianz.com/'
export const InsuranceCoverageBajajAllianzLinkText = 'www.bajajallianz.com'

////---------Insurance coverage end--------////


////---------404 page start--------////

export const pageNotFoundTitle = 'Oops!'
export const pageNotFoundMsg = '404 - PAGE NOT FOUND'
export const pageNotFoundHomeBtnText = 'GO TO HOME PAGE'

////---------404 page end--------////

export const deviceProtectTitle = 'BOXX Cyber Safe (Device Protect Edition)'
export const deviceProtectDescription = 'Your ‘Device Protect’ package provides a cyber protection tool to keep you digitally safe and also provides your smartphones, tablets protection against any damage to the screens..'
export const deviceProtectProducts = [
    //     {
    //     "product_name": "BOXX Identity Monitoring",
    //     "product_description": "",
    //     "features": [
    //         "Protect your identity by monitoring your personal details (phone number and email address) for possible breaches and exposure with Dark Web Monitoring.",
    //         "Check if your personal details have been exposed to a data breach. If they have, we’ll tell you where it happened and what to do next.",
    //         "Our cutting-edge surveillance combines Dark Web Monitoring and human intelligence so that you can stay one step ahead",
    //         "Family plan - 3+1 devices / members."
    //     ],
    //     "help_txt": ""
    // }, {
    //     "product_name": "BOXX Online Safety Awareness & Training",
    //     "product_description": "",
    //     "features": [
    //         "Informative learning modules and videos to help you understand how to protect yourself online and what to look out for to keep yourself digitally safe. Build cyber resilience through the visibility of the vulnerability in your digital risk posture.",
    //         "Raise cyber security awareness – and decrease your risk of being a victim of cybercrime"
    //     ],
    //     "help_txt": "For users to access Identity Monitoring and Online Safety Awareness & Training please log in to <a href='https://cybersafe.boxxinsurance.com/?plan=CyberSafe' class='text-blue-500 underline'> CyberSafe Portal<a/>"
    // },
    {
        "product_name": "Device Protect by Across Assist Private Limited",
        "product_description": "Get a One Time Screen Replacement cover for smartphones and tablets up to a max of <b>Rs. 10,000<b>",
        "features": [],
        "help_txt": ""
    },]
export const deviceProtectProductsHelp = [{
    "title": "How to activate your cover?",
    "description": "",
    "steps": [
        "Please click on the link <a href='https://bit.ly/48Q5hk0' class='text-blue-500 underline'>https://bit.ly/48Q5hk0</a> to upload a photo of the device, invoice, etc.",
        "The activation has to be carried out within a maximum of up to 15 days from receiving the Welcome Leer.",
        "Once the details are submitted on the link as given in (i), your plan becomes activate. There will also be a waiting period of 10 days here",
    ],
}]
export const deviceProtectGnrltmsCndtins = [{
    "title": "GENERAL TERMS AND CONDITIONS",
    "description": "",
    "termsConditions": {
        "annual": [
            "Plan Duration - The plan is valid for <b>one year</b> from the date of activation.",
            "Access Fees - A 2% access fee will be charged on the screen repair cost.",
            "Claim Process - Customers should visit a service centre for cashless claim settlement",
            "If there is no such facility available nearby for screen repair, customers are required to visit a nearby service centre",
            "A valid bill from the service centre must be provided for reimbursement",
            "The reimbursement amount will be processed by the terms of the selected plan",
            "No pickup & drop will be available",
            "Additional Terms & Conditions apply, and you can view these here <a href='https://boxxinsurance.com/boxxcybersafe-terms/' class='text-blue-500 underline'>https://boxxinsurance.com/boxxcybersafe-terms/</a>"
        ],
        "monthly": [
            "Plan Duration - The plan is valid for <b>one month</b> from the date of activation.",
            "Access Fees - A 2% access fee will be charged on the screen repair cost.",
            "Claim Process - Customers should visit a service centre for cashless claim settlement",
            "If there is no such facility available nearby for screen repair, customers are required to visit a nearby service centre",
            "A valid bill from the service centre must be provided for reimbursement",
            "The reimbursement amount will be processed by the terms of the selected plan",
            "No pickup & drop will be available",
            "Additional Terms & Conditions apply, and you can view these here <a href='https://boxxinsurance.com/boxxcybersafe-terms/' class='text-blue-500 underline'>https://boxxinsurance.com/boxxcybersafe-terms/</a>"
        ]
    },
}]
export const deviceProtectProductsFooter = { "footer": "<b>Device Protect</b> is the proposition provided by UMBO ID TECH PRIVATE LIMITED issued to LOCON SOLUTIONS PRIVATE LIMITED for its customers. The One Time Screen Replacement is offered by Across Assist Pvt. Ltd. To reach out for any assistance on the program, call the Assistance helpline at +919372776177" }

export const saferHomesTitle = "BOXX Cyber Safe (Safer Homes Edition)"
export const saferHomesDesc = "‘Safer homes’ provides a cyber protection tool to keep you digitally safe, and helps protect your home from unforeseen incidents like natural calamities, burglary, breakdown of appliances."
export const saferHomesProducts = [
    // {
    //     "product_name": "BOXX Identity Monitoring",
    //     "product_description": "",
    //     "features": [
    //         "Protect your identity by monitoring your personal details (phone number and email address) for possible breaches and exposure with Dark Web Monitoring.",
    //         "Check if your personal details have been exposed to a data breach. If they have, we’ll tell you where it happened and what to do next.",
    //         "Our cutting-edge surveillance combines Dark Web Monitoring and human intelligence so that you can stay one step ahead",
    //         "Family plan - 3+1 devices / members."
    //     ],
    //     "help_txt": ""
    // }, {
    //     "product_name": "BOXX Online Safety Awareness & Training",
    //     "product_description": "",
    //     "features": [
    //         "Informative learning modules and videos to help you understand how to protect yourself online and what to look out for to keep yourself digitally safe. Build cyber resilience through the visibility of the vulnerability in your digital risk posture.",
    //         "Raise cyber security awareness – and decrease your risk of being a victim of cybercrime"
    //     ],
    //     "help_txt": "For users to access Identity Monitoring and Online Safety Awareness & Training please log in to <a href='https://cybersafe.boxxinsurance.com/?plan=CyberSafe' class='text-blue-500 underline'> CyberSafe Portal<a/>"
    // },
    {
        "product_name": "Householders Insurance Policy by Bajaj Allianz General Insurance Company Limited",
        "product_description": "The Insurance Company shall indemnify the Insured Beneficiary during the Cover Period for Loss as a result of :",
        "features": [
            "Fire - Get your home contents [furniture, fixtures, electronic equipments] covered against Storms, Tempest, Floods, Inundation, Earthquake, Terrorism up to Rs. 75,000",
            "Burglary - Your home contents [furniture, fixtures, electronic equipments] are covered up to Rs. 75,000 in case of a robbery in your home",
            "Breakdown of Domestic Appliances - Your TV, Desktop, Music System are covered up to Rs. 25,000 in case of any physical damage to them",
            "Electronic Equipment Cover - In case breakdown/physical damage of Washing Machine, Refrigerator, Oven, Mixer, Grinder get covered up to Rs. 25,000",
            "Baggage Cover - In case loss of clothes or shoes during travel, get compensated up to Rs. 10,000",
            "Accidental Damage Cover - Policy is extended to cover direct physical loss or damage to General Contents insured under the Policy, whilst situated in the insured Premises due to accident from any fortuitous cause subject to the terms, conditions and exclusions herein"
        ],
        "help_txt": ""
    },]
export const saferHomesFooter = { "footer": "<b>Safer Homes Plan</b> is the proposition provided by UMBO ID TECH PRIVATE LIMITED issued to LOCON SOLUTIONS PRIVATE LIMITED for its. The insurance benefits are underwritten by Bajaj Allianz General Insurance Pvt. Ltd. To reach out for any assistance on the program, call the Assistance helpline at +919372776177" }
export const saferHomesExclusions = [
    "Portable devices, paintings, jewellery, and any other valuables excluded",
    "There will be no selection of sum insured in fire and burglary section",
    "Sum insured taken in MBD should be the part of fire and burglary sum insured i.e. same should be included in fire and burglary section sum insured",
    "Equipment covered in MBD/EEIP should not be more than 5 years old",
    [
        "5% of claim amount subject to min of Rs. 2500 for Burglary",
        "1% of Sum Insured subject to min of Rs. 2500 for Breakdown of Domestic Appliances (Non AC)",
        "First 10% or Rs.2,500 (whichever is higher) of any claim concerning computers",
        "First 10% or Rs.2500/- (whichever is higher) for Electronic equipment cover"
    ],
    "Intentional/wilful destruction of the property (home and contents)",
    "Any property which is kutcha construction",
    "Pre-existing damage to the structure of your home and contents",
    "Manufacturing defects in electrical, mechanical and electronic items",
    "Loss/damage to articles of consumable nature",
    "Mysterious disappearance and unexplained losses",
    "Improper handling of contents"
]