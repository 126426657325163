import React from 'react'
import { Navigate } from 'react-router-dom'
import LoginForm from '../components/login/loginForm'
import { authUtils } from '../helpers/authHelper'
import { localStorageUtils } from '../helpers/storageHelper'
import { planSaferDevice, planSaferHomes } from '../helpers/constants'

const Login = () => {

  let policyPremium = []
  if (authUtils.isAuthenticated()) {
    console.log('Is authenticated')
    const user = localStorageUtils.getLocalStorageItem('user');
    if (user) {
      policyPremium = user.policy.reduce((maxPolicy, policy) => {
        const premiumValue = parseInt(policy.premium, 10);

        if (premiumValue > maxPolicy.premiumValue) {
          return {
            premiumValue: premiumValue,
            policyObject: policy
          };
        } else {
          return maxPolicy;
        }
      }, { premiumValue: -1, policyObject: null }).policyObject;
    }
    if (user && user.policy && policyPremium && policyPremium.premium) {
      switch (policyPremium.premium) {
        case planSaferDevice:
          return <Navigate to='/device-protect' />

        case planSaferHomes:
          return <Navigate to='/safer-homes' />

        default:
          return <Navigate to='/boxx' />

      }
    }
    return <Navigate to='/boxx' />
  }
  return (
    <div className='w-full h-full flex flex-col items-center justify-center bg-app-bg text-white left-0 z-10 pt-28'>
      <div className='w-full flex flex-col items-center sm:space-y-0 space-y-12'>
        <div className='w-full sm:hidden flex flex-col items-center'>
          <img className='min-w-[140px] object-contain' src="/images/dark/boxx.svg" alt="boxx logo" />
        </div>
        <LoginForm />
      </div>
    </div>
  )
}

export default Login