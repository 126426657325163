import { deviceProtectDescription, deviceProtectGnrltmsCndtins, deviceProtectProducts, deviceProtectProductsFooter, deviceProtectProductsHelp } from "../../helpers/constants"

const Details = ({ monthly }) => {
    return (
        <div className='w-full flex justify-center font-Roboto'>
            <div className='w-full py-10 max-w-4xl border-b border-white border-opacity-20 space-y-7'>
                <div className='w-full space-y-8'>
                    <div className='w-full space-y-2'>
                        <p className='font-normal text-sm text-simple-gray leading-5'>{deviceProtectDescription}</p>
                    </div>
                    {deviceProtectProducts.map((product, index) => (
                        <div className='w-full space-y-4' key={index}>
                            <h2 className='font-bold text-base text-extra-dark-gray leading-6'>{product.product_name}</h2>
                            <p className='font-normal text-sm text-simple-gray leading-5' dangerouslySetInnerHTML={{ __html: product.product_description }}></p>
                            <ul className='pl-6 font-normal text-sm text-simple-gray leading-5 list-disc list-outside'>
                                {product.features.map((feature, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: feature }}></li>
                                ))}
                            </ul>
                            <p className='font-normal text-sm text-simple-gray leading-5' dangerouslySetInnerHTML={{ __html: product.help_txt }}></p>
                        </div>
                    ))}

                    {deviceProtectProductsHelp.map((help, index) => (
                        <div className='w-full space-y-4' key={index}>
                            <h2 className='font-bold text-base text-extra-dark-gray leading-6'>{help.title}</h2>
                            <p className='font-normal text-sm text-simple-gray leading-5' dangerouslySetInnerHTML={{ __html: help.description }}></p>
                            <ul className='pl-6 font-normal text-sm text-simple-gray leading-5 list-disc list-outside'>
                                {help.steps.map((step, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: step }}></li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <p className='font-normal text-sm text-simple-gray leading-5' dangerouslySetInnerHTML={{ __html: deviceProtectProductsFooter.footer }}></p>

                <div class="bg-blue-100 border border-blue-500 text-gray-700 px-4 py-3" role="alert">
                    {deviceProtectGnrltmsCndtins.map((termsConditions, index) => (
                        <div className='w-full space-y-4' key={index}>
                            <p class="font-bold">{termsConditions.title}</p>
                            <p className='font-normal text-sm text-simple-gray leading-5' dangerouslySetInnerHTML={{ __html: termsConditions.description }}></p>
                            <ul className='pl-6 font-normal text-sm text-simple-gray leading-5 list-disc list-outside'>
                                {(monthly ? termsConditions.termsConditions.monthly : termsConditions.termsConditions.annual)?.map((termsConditions, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: termsConditions }}></li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    <p className="text-sm">Please refer to the policy wordings for detailed information on exclusions and other terms and conditions.</p>
                </div>
            </div>
        </div>
    )
}
export default Details